function isFirefox() {
  const ua = window.navigator.userAgent.toLowerCase();

  return ua.indexOf("firefox") !== -1;
}

function isSafari() {
  const ua = window.navigator.userAgent.toLowerCase();

  return (
    ua.indexOf("safari") !== -1 &&
    !(ua.indexOf("chrome") !== -1) &&
    ua.indexOf("version/") !== -1
  );
}

function supportsHEVCAlpha() {
  if (isFirefox()) {
    return false;
  }

  if (isSafari()) {
    const hasMediaCapabilities =
      window.navigator.mediaCapabilities &&
      window.navigator.mediaCapabilities.decodingInfo;

    return hasMediaCapabilities;
  }

  return true;
}

function main() {
  const player = document.getElementById("player");

  player.src = supportsHEVCAlpha()
    ? new URL("../public/bg.mov", import.meta.url)
    : new URL("../public/bg.webm", import.meta.url);
}

window.addEventListener("DOMContentLoaded", () => main());
